.notification_label {
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
}

.label_disabled {
  color: var(--gray700);

  &:hover {
    cursor: not-allowed;
  }
}

.provider_container {
  display: flex;
  flex-direction: column;
}

.provider_title {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.provider_setting_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
}

.provider_setting {
  display: flex;
  flex-direction: column;
}

.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 6px;
  width: 440px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--gray150);
  border-radius: 4px;
}

.input_disabled {
  background-color: var(--gray100);
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--black);

  &:disabled {
    color: var(--gray500);
    cursor: not-allowed;
  }
}

.input_buttons_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  gap: 6px;
}

.btn_cancel {
  color: var(--gray850);
  border-color: var(--gray100);
  background-color: var(--white);

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
}

.btn_delete {
  color: var(--white);
  border-color: var(--gray500);
  background-color: var(--gray500);

  &:hover {
    border-color: var(--red500);
    background-color: var(--red500);
  }
  &:active {
    border-color: var(--red400);
    background-color: var(--red400);
  }
}

.btn_submit {
  color: var(--white);
  border-color: var(--brand500);
  background-color: var(--brand500);

  &:hover {
    background-color: var(--brand600);
  }
  &:active {
    background-color: var(--brand700);
  }
}

.btn_cancel,
.btn_delete,
.btn_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  min-width: 60px;
  height: 28px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  user-select: none;
  cursor: pointer;

  &:disabled {
    border-color: var(--gray150);
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}

.btn_icn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: var(--gray500);

  path {
    fill: var(--white);
  }

  &:hover {
    background-color: var(--red500);
  }
  &:active {
    background-color: var(--red400);
  }
}

.information_container {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}

.information_title {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
}

.information {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--gray850);
  font-size: 14px;

  span {
    font-weight: 500;
    font-style: italic;
    color: var(--black);
    text-decoration: underline;
  }
}

.information_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }
}

.toggle_switch_container {
  display: flex;
  flex-direction: row;
}
