.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 72px;
  border-top: 2px solid var(--gray150);
}

.trigger_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 56px;
}

.trigger_info_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.btn_toggle_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trigger_info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.trigger_name {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}

.trigger_description {
  font-size: 12px;
  color: var(--gray800);
}

.trigger_option_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
