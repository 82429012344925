.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inner_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  color: var(--gray500);
  border-radius: 12px;

  path {
    fill: var(--gray500);
  }

  &:hover {
    color: var(--gray850);

    path {
      fill: var(--gray850);
    }
  }
}

.border {
  border: 1px solid var(--gray100);
  color: var(--gray850);

  path {
    fill: var(--gray850);
  }

  &:hover {
    border-color: var(--gray850);
    background-color: initial;
  }
}

.name {
  font-size: 14px;
  font-weight: 600;
  user-select: none;
}

.active {
  color: var(--black);

  path {
    fill: var(--black);
  }
}

.new {
  padding: 0 6px;
  height: 16px;
  border-radius: 15px;
  background-color: var(--orange500);
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.24px;
  color: var(--white);
  cursor: pointer;
}
