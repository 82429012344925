.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
}

.btn_close {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.body_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 576px;
  height: 100%;
  overflow: hidden;
}

.new_feature_container {
  display: flex;
  flex-direction: row;
  transition: transform 200ms ease-in-out 0s;
}

.new_feature {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.btn_arrow {
  position: absolute;
  top: 55%;

  path {
    fill: var(--black);
  }

  &:hover {
    path {
      fill: var(--gray700);
    }
  }
}

.btn_prev {
  left: 32px;
}

.btn_next {
  right: 32px;
}

.header_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  font-weight: 600;
  font-size: 16px;
  color: var(--black);
}

.sub_header {
  font-size: 14px;
  color: var(--gray850);
}

.picture_container {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  width: 576px;
  height: 324px;
  background: var(--gray100);
  overflow: hidden;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.crumb {
  width: 10px;
  height: 10px;
  border: 1px solid var(--gray700);
  border-radius: 5px;

  &:not(.crumb_active):hover {
    background: var(--brand700);
    border-color: var(--brand700);
  }
}

.crumb_active {
  background: var(--brand500);
  border-color: var(--brand500);
}
