.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.link_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.link_name_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  path {
    fill: var(--black);
  }
}

.link_name {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.link_input_container {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
}

.link_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.btn_connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 122px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-align: center;
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}
