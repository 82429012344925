.modal_input_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.input_label {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.input {
  width: 100%;
  background-color: var(--white);
  border: none;
  outline: none;
}

.btn_clear {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;

  path {
    fill: var(--gray500);
  }

  &:hover {
    svg {
      path {
        fill: var(--gray700);
      }
    }
  }
}

.btn_paste {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  width: 168px;
  height: 24px;
  background-color: var(--brand50);
  border-radius: 4px;
  color: var(--brand500);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;

  &:hover {
    background-color: var(--brand500);
    color: var(--brand50);
  }
}
