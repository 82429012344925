.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 12px 16px;
  width: 100%;
  border: 1px solid var(--gray500);
  background-color: var(--white);
  border-radius: 8px;
}

.time {
  font-size: 12px;
  color: var(--gray800);
}

.buttons_container {
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn_remove {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--red400);
  }

  &:hover {
    background-color: var(--gray50);

    path {
      fill: var(--red500);
    }
  }
}

.announcement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.description {
  font-size: 16px;
  color: var(--black);
  white-space: pre-line;
  word-break: break-all;
}

.link {
  color: var(--brand500);
  &:visited {
    color: var(--brand700) !important;
  }
  &:hover {
    text-decoration: underline;
  }
}
