.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
}

.collapsed_container {
  flex-direction: row;
  gap: 0;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  word-break: break-all;
}

.collapsed {
  -webkit-line-clamp: unset;
}

.btn_container {
  display: flex;
  align-self: flex-start;
}

.btn_toggle {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 68px;
  height: 22px;
  font-weight: 500;
  font-size: 12px;
  color: var(--brand500);

  &:hover {
    color: var(--brand600);
  }

  &:active {
    color: var(--brand700);
  }
}
