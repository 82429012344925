.theme_switch_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.theme--switch {
  display: none;
}
.theme--switch:checked + .switch--label {
  background: #6b7abb;
  border-color: #5d6baa;
}
.theme--switch:checked + .switch--label .switch--label-background {
  left: 14px;
  width: 2px;
  height: 2px;
}
.theme--switch:checked + .switch--label .switch--label-background:before {
  top: -4px;
  width: 2px;
  height: 2px;
}
.theme--switch:checked + .switch--label .switch--label-background:after {
  top: 4px;
  left: -6px;
  width: 2px;
  height: 2px;
}
.theme--switch:checked + .switch--label:before {
  background: #fff;
  border-color: #e8e8ea;
  animation-name: switch;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}
.theme--switch:checked + .switch--label:after {
  transition-delay: 350ms;
  opacity: 1;
}
.switch--label {
  position: relative;
  display: flex;
  width: 48px;
  height: 26px;
  background: #96dcee;
  border-radius: 26px;
  border: 2px solid #72cce3;
  cursor: pointer;
  transition: all 350ms ease-in;

  .theme--switch:not(:checked) + &:hover {
    .switch--label-background {
      animation: cloud-move linear 1s infinite;
    }
  }

  .theme--switch:checked + &:hover {
    .switch--label-background {
      animation: blink-effect 1.5s ease-in-out infinite;
      animation-delay: 0.3s;
    }
    .switch--label-background:before {
      animation: blink-effect 1s ease-in-out infinite !important;
      animation-delay: 0.7s !important;
    }
    .switch--label-background:after {
      animation: blink-effect 1s ease infinite !important;
      animation-delay: 0.2s !important;
    }
  }
}
.switch--label-background {
  position: relative;
  top: 10px;
  left: 30px;
  width: 4px;
  height: 2px;
  border-radius: 5px;
  background: #fff;
  transition: all 150ms ease-in;
}
.switch--label-background:before {
  position: absolute;
  top: -2px;
  left: -4px;
  width: 10px;
  height: 2px;
  border-radius: 5px;
  background: #fff;
  transition: all 150ms ease-in;
  content: '';
}
.switch--label-background:after {
  position: absolute;
  top: 2px;
  left: -2px;
  width: 10px;
  height: 2px;
  border-radius: 5px;
  background: #fff;
  transition: all 150ms ease-in;
  content: '';
}
.switch--label:before {
  animation-name: reverse;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  transition: all 350ms ease-in;
  content: '';
  position: absolute;
  top: 1px;
  width: 20px;
  height: 20px;
  border: 2px solid #f5eb71;
  border-radius: 20px;
  background: #fffaa8;
}
.switch--label:after {
  transition-delay: 0ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: '';
  box-shadow: #e8e8ea -13px 0 0 2px, #e8e8ea -16px 4px 0 0px;
  left: 45px;
  top: 6px;
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  opacity: 0;
}

@keyframes switch {
  0% {
    left: 1px;
  }
  60% {
    left: 1px;
    width: 26px;
  }
  100% {
    left: 24px;
    width: 20px;
  }
}
@keyframes reverse {
  0% {
    left: 24px;
    width: 20px;
  }
  60% {
    left: 18px;
    width: 26px;
  }
  100% {
    left: 1px;
  }
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cloud-move {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
