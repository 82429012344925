.submit_modal_container {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
}

.submit_modal_title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.submit_modal_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.center {
  justify-content: center;
}

.submit_modal_body {
  max-height: 613px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.buttons_container {
  margin-top: 16px;
}

.submit_button,
.cancel_button {
  margin-top: 16px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
}

.submit_button {
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}

.cancel_button {
  background-color: var(--gray50);
  color: var(--gray850);

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray500);
    color: var(--gray100);
  }
}
