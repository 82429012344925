.blurred_thumbnail {
  border-radius: 50%;
  background-color: var(--gray150);
  filter: blur(8px);
}

.container {
  border-radius: 50%;
  background-color: var(--gray50);
  line-height: 1;
}
