.container {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 8px;
  background-color: var(--gray50);
  border-left-width: 1px;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--gray150);
  border-style: solid;
  overflow: hidden;

  &:first-child {
    background-color: var(--brand50);
    border-color: var(--brand500);
    border-top-width: 1px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.time {
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  font-size: 12px;
  color: var(--gray800);
}

.title {
  font-weight: 600;
  font-size: 16px;
  word-break: break-all;
}

.description {
  font-size: 14px;
  color: var(--gray850);
  white-space: pre-line;
  word-break: break-all;
}

.link {
  color: var(--brand500);
  &:visited {
    color: var(--brand700) !important;
  }
  &:hover {
    text-decoration: underline;
  }
}
