.theme_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.theme_title {
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray850);
}

.layout_order_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
