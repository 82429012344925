.select_button_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flat {
  li {
    padding: 6px;
    width: 38px;
  }
}

.multi {
  gap: 4px;

  .select_button {
    border-color: var(--gray100);
    border-radius: 4px;
  }
}

.clear_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--brand500);
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--brand600);
  }
  &:active {
    color: var(--brand700);
  }
  &:disabled {
    border-color: var(--gray100);
    color: var(--gray700);
    cursor: default;
  }
}

.select_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 28px;
  border-style: solid;
  border-width: 1px;
  border-left-color: var(--gray100);
  border-top-color: var(--gray100);
  border-right-color: transparent;
  border-bottom-color: var(--gray100);
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  user-select: none;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-color: var(--gray100);
  }

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
}

.active {
  border-color: var(--brand500) !important;
  background-color: var(--brand50);
  color: var(--brand500);

  path {
    fill: var(--brand500) !important;
  }

  & + li {
    border-left-color: transparent !important;
  }

  &:hover,
  &:active {
    background-color: var(--brand50);
  }
}
