.empty_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  color: var(--gray800);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.list_container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
