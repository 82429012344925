.sign_in_connect_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  border: 1px solid var(--gray100);
  border-radius: 12px;
  background-color: var(--white);

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
  &:disabled {
    border-color: var(--gray150);
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}

.sign_in_connect_button_text {
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.24px;
}
