.container {
  display: flex;
  align-items: center;
  max-width: 960px;
  width: 100%;
  background-color: var(--white);
}

.etc_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 96px;
  width: 100%;
  height: calc(100% - 68px);
  color: var(--gray800);
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 48px;
  width: 100%;
  background-color: var(--white);
}

.btn_scroll_top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--brand500);
  transition: background-color 0.2s;

  path {
    fill: var(--white);
  }

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}
