.link {
  color: var(--brand500);
  &:visited {
    color: var(--brand700) !important;
  }
  &:hover {
    text-decoration: underline;
  }
}

.paragraph {
  &:not(:last-child):after {
    content: ' \a\a ';
    white-space: pre-line;
  }
}

.head_markdown {
  display: block;
  font-weight: 600;
}
