.container {
  position: relative;
  min-height: 101px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--gray100);
  cursor: pointer;

  &:last-of-type:not(:first-of-type) {
    border-bottom-color: transparent;
  }

  &:hover {
    background-color: var(--gray50);
  }
}

.unread {
  background-color: var(--brand50);

  .content {
    &::before {
      background-color: var(--brand500);
    }
  }
}

.inner_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 24px;
}

.message {
  font-size: 14px;
  color: var(--gray800);

  strong {
    font-weight: 500;
    color: var(--black);
  }
}

.contract_name {
  &:hover {
    text-decoration: underline;
  }
}

.date {
  margin-bottom: 12px;
  color: var(--gray700);
  font-size: 12px;
  line-height: 1.5;
}

.content {
  position: relative;
  padding-left: 12px;
  font-size: 14px;
  color: var(--black);
  letter-spacing: -0.24px;
  line-height: 22px;
  word-wrap: break-word;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: var(--gray150);
    border-radius: 2px;
  }
}
