$themes: (
  light: (
    white: #ffffff,
    black: #292525,
    skeletonBase: #ebebeb,
    skeletonHighlight: #f5f5f5,
    vendorX: #000000,
    gray50: #f8f8f9,
    gray100: #e8e9ed,
    gray150: #e0e2e8,
    gray500: #bdc2c7,
    gray700: #a3a6af,
    gray800: #6e7079,
    gray850: #595959,
    brand50: #edf5f2,
    brand300: #97d1bf,
    brand400: #64b59c,
    brand500: #44927a,
    brand600: #3a7d68,
    brand700: #145e48,
    green50: #ccebdf,
    green500: #24cc8f,
    orange50: #fff1e3,
    orange500: #ff5f2d,
    red50: #fee2e3,
    red400: #ff5f62,
    red500: #ff2d46,
    yellow500: #ffae34,
  ),
  dark: (
    white: #1e1f21,
    black: #d2d5d8,
    skeletonBase: #232628,
    skeletonHighlight: #1e2021,
    vendorX: #ffffff,
    gray50: #1a1b1c,
    gray100: #2d2f31,
    gray150: #55595b,
    gray500: #72757a,
    gray700: #8a8d93,
    gray800: #aaaeb1,
    gray850: #c0c4c8,
    brand50: #1b2c27,
    brand300: #145e48,
    brand400: #3a7d68,
    brand500: #44927a,
    brand600: #64b59c,
    brand700: #97d1bf,
    green50: #21362e,
    green500: #1f9268,
    orange50: #582312,
    orange500: #e4572a,
    red50: #553337,
    red400: #b93b49,
    red500: #e0374c,
    yellow500: #e09f3b,
  ),
);

@mixin themed($mode) {
  @each $key, $colors in $themes {
    @each $color, $value in $colors {
      @if $key == $mode {
        --#{$color}: #{$value};
      }
    }
  }
}
