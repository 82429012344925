$first-column-width: 42px;
$second-column-width: 100%;
$last-column-width: 148px;
$cell-height: 38px;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: height 300ms ease-out;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:nth-of-type(even) {
    & > div {
      background-color: var(--gray50);
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 0 12px;
  width: 100%;
  height: $cell-height;

  &:hover {
    background-color: var(--brand50) !important;
  }
}

.clustered {
  cursor: pointer;
}

.rank {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: $first-column-width;

  span {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
}

.icn_cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: var(--gray700);
  }
}

.connected_wallets {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: calc(100% - $first-column-width - $last-column-width - 48px);
  min-width: 0px;
  width: 100%;
}

.highlight {
  div {
    filter: grayscale(0);

    p,
    span path,
    path {
      color: var(--black);
      fill: var(--gray850) !important;
    }
  }
}

.quantity_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: $last-column-width;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.quantity {
  width: 80px;
}

.percentage {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 64px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.percentage_bar {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 48px;
  height: 2px;
  border-radius: 2px;
  background-color: var(--gray150);
  overflow: hidden;
}

.filled {
  height: 2px;
  border-radius: 2px;
  background-color: var(--gray850);
}

.btn_toggle_container {
  width: 56px;
}

.btn_toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s ease;
  cursor: pointer;

  path {
    fill: var(--brand500);
  }
}

.not_collapsed {
  background-color: var(--brand50) !important;
}

.btn_collapsed {
  transform: rotate(-180deg);
}
