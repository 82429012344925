.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.value_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.count_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.hint_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.icn_external {
  display: flex;
  margin-left: 4px;

  path {
    fill: var(--black);
  }
}

.additional_indicator {
  display: flex;
  align-items: flex-end;
  align-items: center;
  margin-left: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray800);

  path {
    fill: var(--gray800);
  }
}

.icn_small_external {
  display: flex;
  margin-left: 4px;

  path {
    fill: var(--gray800);
  }
}

.edited {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray800);
}

.icn_alert {
  display: flex;
  justify-content: center;
  margin-right: -2px;
  width: 18px;
  height: 18px;
}
