.loading_container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--brand50);
}

.btn_connect {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-right: 2px;
  width: 164px;
  height: 40px;
  background-color: var(--brand500);
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--white);

  path {
    fill: var(--white);
  }

  &:hover {
    background-color: var(--brand600);
  }
}

.account_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-left: 16px;
  padding-right: 10px;
  width: 164px;
  height: 40px;
  background-color: var(--brand50);
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;

  path {
    fill: var(--gray700);
  }

  &:hover {
    path {
      fill: var(--brand500);
    }
  }
  &:active {
    path {
      fill: var(--brand600);
    }
  }
}

.wallet_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.connect_info {
  margin-top: 2px;
  max-width: 100%;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.24px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.not_signed_in {
  background-color: var(--orange50);
}

.sign_in_text {
  color: var(--orange500);

  path {
    fill: var(--gray700);
  }

  &:hover {
    path {
      fill: var(--orange500) !important;
    }
  }
  &:active {
    path {
      fill: var(--orange500) !important;
    }
  }
}

.wallet {
  margin-top: -2px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.24px;
  user-select: none;
}

.account_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  width: 240px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--black);

  path {
    fill: var(--gray850);
  }
}
