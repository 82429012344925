.options_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.hide_option {
  display: flex;
  flex-direction: column;
}

.option_title {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray850);
}

.option_icon {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--gray850);
  }
}
