.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: var(--gray50);
  opacity: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    background-color: var(--gray150);
  }
}

.btn_normal {
  path {
    fill: var(--gray850);
  }
}

.btn_disabled {
  &:hover {
    path {
      fill: var(--gray850);
    }
    background-color: var(--gray50);
  }
  opacity: 0.25 !important;
  cursor: not-allowed !important;
}

.new_badge {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
