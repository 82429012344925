.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.similar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--red500);
}

.x {
  background-color: var(--vendorX);

  &_icn > path {
    fill: var(--vendorX);
  }
}

.alert_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.alert_title {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  user-select: none;
  cursor: pointer;

  p {
    margin: 0 8px;
    width: 100%;
    line-height: 26px;
  }

  span {
    margin: 2px 0;
    padding: 0 6px;
    background-color: var(--gray100);
    border-radius: 4px;
    color: var(--gray850);
    word-break: break-all;
    word-wrap: break-word;

    &:hover {
      color: var(--brand500);
      background-color: var(--brand50);
    }
  }
}

.accordion_toggle_button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s ease;
  cursor: pointer;

  path {
    fill: var(--gray700);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    background-color: var(--gray150);
  }
}

.collapsed {
  transform: rotate(-180deg);
}

.alert_body_container {
  width: 100%;
  height: 0;
  transition: height 0.35s ease;
}

.alert_body {
  margin-top: 1px;
  padding-top: 24px;
  width: 100%;
}
