.container {
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  min-width: 430px;
  background-color: var(--white);

  &::-webkit-scrollbar {
    display: none;
  }
}

.is_center {
  border-left: 1px solid var(--gray100);
  border-right: 1px solid var(--gray100);
}

.section_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
  height: 64px;
  background-color: var(--white);
}

.section_header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.section_header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.section_header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.chain_filter_container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.last_updated {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray700);

  path {
    fill: var(--gray700);
  }
}

.a_long_time_ago {
  color: var(--red400);

  path {
    fill: var(--red400);
  }
}

.section_body_container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.time_filter_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.filter_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: center;

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
  &:disabled {
    border: 1px solid var(--gray100);
    background-color: var(--gray50) !important;

    span {
      color: var(--gray700) !important;
    }
    cursor: not-allowed;

    path {
      fill: var(--gray700) !important;
    }
  }
}

.filter_icon {
  margin-left: 4px;
  margin-top: 3px;
  svg {
    path {
      fill: var(--gray800);
    }
  }
}

.filter_active {
  border-color: var(--brand500);
  background-color: var(--brand50);
  color: var(--brand500);

  span {
    color: var(--brand500) !important;
  }

  svg {
    path {
      fill: var(--brand500) !important;
    }
  }

  &:hover,
  &:active {
    background-color: var(--brand50) !important;
  }
  &:disabled {
    border: 1px solid var(--gray100);
    background-color: var(--gray50) !important;

    span {
      color: var(--gray700) !important;
    }
    cursor: not-allowed;

    path {
      fill: var(--gray700) !important;
    }
  }
}

.empty_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 96px;
  height: 100%;
  color: var(--gray500);
  letter-spacing: -0.24px;
  text-align: center;
}

.btn_text_open_setting {
  margin-top: 24px;
  padding: 0 14px;
  min-width: 172px;
  height: 40px;
  background-color: var(--brand500);
  border: 1px solid var(--brand500);
  border-radius: 12px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
  color: var(--white);
  user-select: none;

  &:hover {
    background-color: var(--brand600);
    border: 1px solid var(--brand600);
  }
  &:active {
    background-color: var(--brand700);
    border: 1px solid var(--brand700);
  }
}
