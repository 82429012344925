$value_width: 100px;

.wallet_summary {
  width: 560px;
  max-height: 416px;
  box-shadow: var(--popover-shadow);
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  overflow: auto;
  z-index: 1;
}

.wallet_summary_title_container {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 0 12px;
  width: 100%;
  height: 38px;
  background-color: var(--gray50);
  z-index: 1;

  div:first-child {
    a {
      color: var(--black);
    }
    path {
      fill: var(--gray850);
    }
  }
}

.value_columns {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-right: 8px;
}

.from_column,
.to_column {
  display: flex;
  align-items: flex-end;

  span {
    width: $value_width;
    text-align: end;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--gray850);
  }
}

.summary_list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.summary_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-left: 12px;
  padding-right: 10px;
  height: 32px;

  &:hover {
    background-color: var(--brand50);
  }
}

.linked_wallet {
  display: flex;
  width: 100%;
  max-width: 460px;
  overflow: hidden;

  div {
    text-overflow: ellipsis;
  }
}

.from_container,
.to_container {
  display: flex;
  flex-direction: row-reverse;
  width: $value_width;
}

.from_container {
  color: var(--green500);

  path {
    fill: var(--green500);
  }
}

.to_container {
  color: var(--red500);

  path {
    fill: var(--red500);
  }
}

.value {
  display: inline-flex;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-variant-numeric: lining-nums tabular-nums;

  &:hover {
    text-decoration: underline;
  }
}

.integer_part {
  text-align: right;
}

.decimal_part {
  display: inline-flex;
  text-align: left;
  width: 48px;
}

.icn_external {
  display: inline-flex;
  align-items: center;
  margin-left: 2px;
}
