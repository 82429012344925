$left_margin: 24px;
$suggestions_width: 768px;
$search_bar_width: 448px;

.container {
  position: absolute;
  left: calc($left_margin - (($suggestions_width - $search_bar_width) / 2));
  top: 39px;
  margin-top: 8px;
  padding-bottom: 6px;
  width: $suggestions_width;
  box-shadow: var(--popover-shadow);
  border: 1px solid var(--gray150);
  border-radius: 8px;
  background-color: var(--white);
  z-index: 2;
}

.item_container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 0px;
  max-height: 504px;
  background-color: var(--white);
}
