.minted_out_container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  min-height: 72px;
  height: 100%;
  border-top: 1px solid var(--gray100);
  background-color: var(--gray100);
  z-index: 100;
  cursor: not-allowed;
}

.minted_out {
  font-weight: 600;
  color: var(--gray500);
  text-align: center;
  user-select: none;
}

.container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 72px;
  border-top: 1px solid var(--brand500);
  background-color: var(--brand50);
  z-index: 100;
}

.container_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-left: 24px;
  padding-right: 24px;
  min-width: 617px;
  max-width: 907px;
  width: 100%;
  height: inherit;
}

.beta_container {
  position: absolute;
  top: -20px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 6px;
  background: linear-gradient(var(--white), 40%, var(--brand50));
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 0;
  border-style: solid;
  border-color: var(--brand500);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
}

.function_info_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  min-width: 0;
  height: 40px;
}

.selectable_function {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }
}

.selected_function_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  overflow: hidden;
}

.selected_function {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  min-width: 0;
  padding-left: 8px;
  padding-right: 2px;
  height: 40px;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  background-color: var(--white);
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 12px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: var(--gray50);
  }
}

.icn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow_down {
  path {
    fill: var(--gray800);
  }
}

.address_param {
  path {
    fill: var(--red400);
  }
}

.byte_param {
  path {
    fill: var(--yellow500);
  }
}

.params_popover {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 10px;
  max-width: 600px;
  border-radius: 6px;
  border: 1px;
  border-style: solid;
  border-color: var(--gray150);
  background-color: var(--white);
  overflow: hidden;
}

.param_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  font-size: 12px;
  text-align: left;
  word-break: break-all;
}

.param_name {
  font-weight: 500;
  color: var(--black);
}

.param {
  color: var(--gray850);
}

.selected_function_parameters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 4px;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  background-color: var(--white);
  user-select: none;
  cursor: pointer;

  path {
    fill: var(--gray800);
  }

  &:hover {
    background-color: var(--gray50);
  }

  &:active {
    background-color: var(--gray100);

    path {
      fill: var(--gray850);
    }
  }
}

.mint_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.sweep_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  background-color: var(--white);
}

.disabled {
  background-color: var(--gray100) !important;
  cursor: not-allowed !important;
}

.icn_sweep_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-right: 1px solid var(--gray150);

  path {
    fill: var(--gray800);
  }
}

.slider_container {
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  width: 100px;
  touch-action: none;

  &:hover {
    border-color: var(--gray700);
  }
}

.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-left: 4px;
  height: inherit;
  border-left: 1px solid var(--gray150);
  overflow: hidden;

  input {
    width: 28px;
    border: 0;
    outline: 0;
    background-color: var(--white);
    text-align: right;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.btn_max {
  white-space: nowrap;
  font-size: 10px;
  color: var(--gray700);
}

.btn_steps_container {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--gray150);
  overflow: hidden;
}

.btn_step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 19px;
  overflow: hidden;
  cursor: pointer;

  &:first-of-type {
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    border-bottom-right-radius: 4px;
  }

  path {
    fill: var(--gray850);
  }

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray150);
  }
}

.btn_mint {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  width: 200px;
  height: 40px;
  border: 1px solid var(--gray100);
  border-radius: 6px;
  background-color: var(--gray100);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  color: var(--gray700);

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    background-color: var(--brand500);
    border-color: var(--brand500);
    color: var(--white);

    &:hover {
      background-color: var(--brand600);
      border-color: var(--brand600);
    }

    &:active {
      background-color: var(--brand700);
      border-color: var(--brand700);
    }
  }
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
}

.amount_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  white-space: nowrap;
}

.amount {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suffix {
  font-size: 10px;
}

.price_container {
  display: flex;
  flex-direction: row;
  align-items: center;

  path {
    fill: var(--white);
  }
}

.price {
  font-size: 14px;
  color: var(--white);
}
