.filter_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 12px;
}

.row_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filter_label {
  padding-right: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  user-select: none;
}

.price_input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.price_range_input {
  padding: 4px 4px 4px 8px;
  width: 64px;
  height: 28px;
  outline: none;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  color: var(--gray700);

  &:focus {
    background-color: var(--brand50);
    border-color: var(--brand500);
    color: var(--black);
  }

  &:disabled {
    background-color: var(--gray100);
    cursor: not-allowed;
  }
}

.filter_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: center;

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
  &:disabled {
    border: 1px solid var(--gray100);
    background-color: var(--gray50) !important;
    cursor: not-allowed;

    span {
      color: var(--gray700) !important;
    }

    path {
      fill: var(--gray700) !important;
    }
  }
}

.filter_icon {
  margin-left: 4px;
  margin-top: 3px;
  svg {
    path {
      fill: var(--gray800);
    }
  }
}

.filter_active {
  border-color: var(--brand500);
  background-color: var(--brand50);
  color: var(--brand500);

  span {
    color: var(--brand500) !important;
  }

  svg {
    path {
      fill: var(--brand500) !important;
    }
  }

  &:hover,
  &:active {
    background-color: var(--brand50) !important;
  }
  &:disabled {
    border: 1px solid var(--gray100);
    background-color: var(--gray50) !important;

    span {
      color: var(--gray700) !important;
    }
    cursor: not-allowed;

    path {
      fill: var(--gray700) !important;
    }
  }
}
