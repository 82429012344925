.container {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.icon_container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
