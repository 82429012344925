.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray100);
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
  transition-duration: 0.6s;
  overflow: hidden;
}

.bound {
  background-color: var(--brand50);
  border-color: var(--brand500);
}
