.section_header_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.section_help {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.method_names_filter {
  margin-top: 8px;
}

.table_container {
  position: relative;
  margin-top: 16px;
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  outline: hidden;
  box-shadow: 0 0 0 1px var(--gray100);

  .table_inner_container {
    margin-left: 75px;
    margin-right: 82px;
    overflow-x: overlay;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      position: relative;
      bottom: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      position: relative;
      bottom: 6px;
      background-color: var(--gray500);
    }
    &::-webkit-scrollbar-track {
      position: relative;
      bottom: 6px;
    }
    td {
      white-space: nowrap;
      word-wrap: normal !important;
      word-break: normal !important;

      &:first-child {
        width: 76px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }
    }
    th,
    td {
      padding: 8px 12px;
      letter-spacing: -0.24px;
      white-space: nowrap;
    }
  }

  .minted_out {
    margin-right: 99px !important;
  }

  .empty {
    margin-bottom: 1px;
  }
}

.table_custom_style {
  table-layout: auto;
  box-shadow: none;
  margin: 0;
}

.table_header_custom_style {
  position: static !important;
  box-shadow: none;
}

.table_header_cell {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.table_header_absolute_cell {
  height: 34px;
}

.table_body_absolute_cell {
  width: 77px;
  height: 38px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.table_body_absolute_button {
  width: 84px;
}

.table_header_absolute_left_cell {
  height: 34px !important;
  position: absolute;
  left: 0;
}

.table_body_absolute_left_cell {
  width: 77px;
  height: 38px !important;
  position: absolute;
  left: 0;
}

.table_header_absolute_right_cell {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px !important;
}

.table_body_absolute_right_cell {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 38px !important;
}

.transaction_hash_link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  path {
    fill: var(--gray850);
  }
  &:hover {
    text-decoration: underline;
  }
}

.cell_divided_left {
  padding: 0 6px 0 12px !important;
}

.cell_divided_right {
  padding: 0 0 0 6px !important;
  width: 0;
}

.cell_divided_unit {
  padding: 0 12px 0 6px !important;
  width: 0px;
}

.cell_divider {
  padding: 0 !important;
  color: var(--gray500);
  text-align: center;
}

.cell_time_unit {
  padding: 0 12px 0 0 !important;
  width: 0px;
  text-align: left;
}

.under_one_min {
  color: var(--red500);
}

.value_empty {
  padding: 0 !important;
  width: 0;
}

.value_integer {
  padding: 0 0 0 12px !important;
}

.value_decimal {
  padding: 0 12px 0 0 !important;
  width: 0;
}

.value_decimal_point {
  padding: 0 !important;
  width: 0;
}

.minted_out_cell {
  width: 100px !important;
}

.mint_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  background-color: var(--gray100);
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--gray500);
  cursor: not-allowed;
}

.minted_out_button {
  width: 76px;
}

.active {
  background-color: var(--brand500);
  color: var(--white);
  cursor: pointer;

  &:hover {
    background-color: var(--brand600);
  }
  &:active {
    background-color: var(--brand700);
  }
}

.mint_bot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 24px;
  border-radius: 4px;
  background-color: var(--gray100);
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  cursor: pointer;

  span {
    margin-left: 6px;
  }

  &:hover {
    background-color: var(--gray150);
    color: var(--black);
  }
}

.inactive_qt {
  background-color: var(--gray100);
  color: var(--gray500);
  filter: grayscale(100%);

  svg {
    opacity: 0.6;
  }

  &:hover {
    background-color: var(--gray100);
    color: var(--gray500);
  }
  cursor: not-allowed;
}

.filter_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-end;
}

.filter_item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  height: 28px;
  border-style: solid;
  border-width: 1px;
  border-left-color: var(--gray100);
  border-top-color: var(--gray100);
  border-right-color: transparent;
  border-bottom-color: var(--gray100);
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  user-select: none;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-color: var(--gray100);
  }

  &:hover {
    background-color: var(--gray50);
  }
}

.filter_active {
  border-width: 1px;
  border-color: var(--brand500) !important;
  background-color: var(--brand50);
  color: var(--brand500);

  &:hover {
    background-color: var(--brand50);
  }
}

.successful_transaction {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  div {
    &:first-child {
      display: flex;
      flex-direction: column;
    }
  }
}

.automation_container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  filter: grayscale(100%);
  cursor: pointer;

  svg {
    opacity: 0.4;
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    background-color: var(--gray150);
  }
}

.quick_task {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  text-align: center;
}

.automation_active {
  svg {
    opacity: 1;
  }
  filter: grayscale(0);
}

.airdrop_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.method_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.param_needed_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address_param {
  path {
    fill: var(--red400);
  }
}

.byte_param {
  path {
    fill: var(--yellow500);
  }
}
