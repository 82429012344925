.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid var(--white);
  border-radius: 4px;
  border-color: transparent transparent var(--white) var(--white);
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: var(--popover-arrow-shadow);
}
