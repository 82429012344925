.copied_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
  user-select: none;
}

.copied {
  margin-left: 2px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--brand500);
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button_copy_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;

  &:hover {
    path {
      fill: var(--brand500);
    }
  }
}
