.container {
  width: 100%;
}

.section_title_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.section_title_left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.tags_filter_container {
  display: flex;
}

.minting_aggregates_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.minting_aggregates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.aggregate_label {
  margin-right: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray800);
  white-space: nowrap;
  user-select: none;
}

.aggregate_data {
  display: flex;
  flex-direction: row;
}

.wallet_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}

.odd {
  li:last-child {
    margin-right: 0 !important;
  }
}

.tags_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  font-family: Poppins;
}

.tag {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid var(--brand500);
  border-radius: 4px;
  background-color: var(--brand50);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--brand500);
  user-select: none;

  &:last-of-type {
    margin-right: 0px;
  }
}

.no_notable_groups_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.no_notable_groups_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.no_notable_groups_title {
  margin-left: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.no_notable_groups_curated {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.notable_group {
  &:hover {
    text-decoration: underline;
  }
}

.report_another_group_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
  background-color: var(--brand500);
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--white);
  text-align: center;

  path {
    fill: var(--white);
  }

  &:hover {
    background-color: var(--brand600);
    color: var(--white);
  }

  &:active {
    background-color: var(--brand700) !important;
  }
}

.btn_following {
  position: relative;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid var(--gray100);
  background-color: var(--white);
  color: var(--black);

  span {
    font-weight: 600;
    color: var(--brand500);
  }
  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
}

.new {
  position: absolute;
  top: -8px;
  right: -16px;
  padding: 0 6px;
  height: 16px;
  border-radius: 15px;
  background-color: var(--orange500);
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.24px;
  color: var(--white);
  cursor: pointer;
}

.table_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
