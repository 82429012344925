.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
}

.btn_close {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.announcements {
  max-height: 720px;
  overflow-y: auto;
}
