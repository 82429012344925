.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
}

.btn_close {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.body_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.input {
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  outline-color: var(--brand500);
}

.btn_connect {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  background-color: var(--brand500);
  font-weight: 600;
  font-size: 16px;
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
  &:disabled {
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}
