.profile_details_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail_container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:last-of-type {
    margin-top: 18px;
  }
}

.social_connection_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.social_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.social_profile_label {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray850);

  svg {
    path {
      fill: var(--gray850);
    }
  }
}

.social_profile_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.error_msg {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--red500);
}

.btn_connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 122px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-align: center;
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}

.karma_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  path {
    fill: var(--gray850);
  }
}

.karma {
  font-weight: 500;
  color: var(--gray800);
}
