.btn {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 8px;
  height: 82px;
  color: var(--white);
  background-color: var(--brand500);
  border-radius: 4px;

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }

  &:disabled {
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}

.option_loading {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confidence {
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  letter-spacing: -0.24px;
  line-height: 1.5;
}

.label_option {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.label {
  font-size: 10px;
  font-weight: 500;
}

.value_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
}

.value {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: right;
}

.text_write {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.unit {
  font-size: 10px;
}
