.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  max-width: 169px;
  overflow: hidden;
}

.art_item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 169px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  img,
  video {
    transition: 0.25s transform;
  }

  &:hover {
    .btn_lens,
    .btn_lens_background {
      display: block;
    }

    img,
    video {
      transform: scale(1.1);
    }
    span {
      text-decoration: underline;
    }
  }
}

.btn_lens {
  position: absolute;
  top: 4%;
  right: 4%;
  display: none;
  width: 24px;
  height: 24px;
  opacity: 0.6;

  svg {
    path {
      fill: var(--black);
    }
  }

  &:hover {
    opacity: 1;
  }
}

.btn_lens_background {
  position: absolute;
  top: 4%;
  right: 4%;
  display: none;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--gray100);
  opacity: 0.8;
}

.art_container {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1;
}

.art_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.name {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.24px;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.token_id {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.24px;
  color: var(--brand500);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.etc_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.value {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.24px;
  color: var(--gray800);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  path {
    fill: var(--gray700);
  }
}

.time {
  font-size: 10px;
  color: var(--gray700);
}
