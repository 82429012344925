.details {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 364px;
  background-color: var(--white) !important;
  overflow: hidden;
}

.chart_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 280px);
  height: 100%;
}

.chart_error {
  color: var(--gray500);
}

.connected_wallet_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 12px;
  width: 280px;
  height: 28px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);

    p,
    span {
      color: var(--gray850);
    }

    path {
      fill: var(--gray850) !important;
    }
  }
}

.clustered_addresses {
  overflow-y: auto;
  overflow-x: hidden;
}

.focused {
  background-color: var(--brand500);

  p,
  span {
    color: var(--white);

    path {
      fill: var(--white) !important;
    }
  }

  &:hover {
    background-color: var(--brand600);
  }
  &:active {
    background-color: var(--brand600);

    p,
    span {
      color: var(--white);
    }

    path {
      fill: var(--white) !important;
    }
  }
}

.hovered {
  background-color: var(--gray50);
}

.focused_hovered {
  background-color: var(--brand600);
}

.linked {
  background-color: var(--brand50);
}

.quantity {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  pointer-events: none;
}
