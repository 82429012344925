.failed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  path {
    fill: var(--gray700);
  }
}

.img {
  pointer-events: none;
  aspect-ratio: 1;
  object-fit: cover;

  path {
    fill: var(--gray700);
  }
}

.radius {
  border-radius: 50%;
}

.skeleton_container {
  width: 100%;
  height: 100%;
  line-height: 1;
}
