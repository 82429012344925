.group_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 6px 24px;
  height: 36px;
  background-color: var(--white);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
  transition-duration: 0.6s;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }

  &:active {
    background-color: var(--gray100);
  }
}

.bound {
  background-color: var(--brand50);
}

.no_transition {
  transition-duration: 0s;
}

.group_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
}

.group_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  background-color: var(--brand50);

  &:hover {
    background-color: var(--brand50);
  }
}

.volume_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
}

.volume {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-variant-numeric: lining-nums tabular-nums;
  color: var(--gray700);
}

.icn_ethereum {
  display: flex;
  align-items: center;
  justify-content: center;
}
