.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}

.hidden {
  display: none;
}

.field_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.field_name_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.field_name {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.field_error_container {
  display: flex;
  align-items: flex-start;
}

.field_error {
  font-size: 14px;
  color: var(--red500);
}

.label_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 8px;
  width: inherit;
}

.input {
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  outline-color: var(--brand500);
}

.textarea {
  padding: 12px 16px;
  width: 100%;
  min-height: 196px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 16px;
  color: var(--black);
  resize: none;
  outline-color: var(--brand500);
}

.input_warning,
.textarea_warning {
  border-color: var(--red500);
  outline-color: var(--red500);
}

.warning_info {
  font-weight: 600;
  font-size: 14px;
  color: var(--red500);
}
