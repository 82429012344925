@import './minireset.scss';
@import './themes.scss';
@import './common.scss';

:root {
  @include themed('light');
  --alert-background: rgba(0, 0, 0, 0.65);
  --popover-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  --popover-arrow-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
  --modal-shadow: rgba(0, 0, 0, 0.5);
  --notification-modal-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  --scroll-shadow: rgba(0, 0, 0, 0.35);
}

[data-theme='dark'] {
  @include themed('dark');
  --alert-background: rgba(255, 255, 255, 0.65);
  --popover-shadow: 0px 4px 16px rgba(255, 255, 255, 0.15);
  --popover-arrow-shadow: -3px 3px 3px 0 rgba(255, 255, 255, 0.05);
  --modal-shadow: rgba(0, 0, 0, 0.85);
  --notification-modal-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2);
  --scroll-shadow: rgba(255, 255, 255, 0.35);
}

html,
body {
  font-family: Poppins;
  color: var(--black);
  background-color: var(--gray50);
  height: 100%;
  overscroll-behavior-y: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#__next {
  height: 100%;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  user-select: none;
}
input[type='radio'] {
  margin: 4px;
  padding: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--gray700);
  border-radius: 50%;
  background-clip: content-box;
  appearance: none;
  accent-color: var(--brand500);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--brand500);
    background-clip: padding-box;
    background-color: var(--brand50);
  }
}
input[type='radio'] + label {
  user-select: none;
  cursor: pointer;
}
input[type='radio']:checked {
  background-clip: content-box;
  background-color: var(--brand500);
  border: 1px solid var(--brand500);
}
input[type='radio']:disabled {
  padding: 0;
  background-color: var(--gray100);
  border-color: var(--gray500);
  cursor: not-allowed;
}
input[type='radio']:disabled + label {
  & > p,
  span {
    color: var(--gray700);
  }
  color: var(--gray700);
  filter: grayscale(100%);

  cursor: not-allowed;
}
input[type='file'] {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
input {
  &::placeholder {
    color: var(--gray500);
  }
}

textarea {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  user-select: none;
}

a {
  all: unset;
}
a:link,
a:visited,
a:active {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}

* {
  // Remove shadow of button touch
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  // Prevent link long touch
  -webkit-touch-callout: none;
}

.brand_popover {
  padding: 4px 10px;
  border-radius: 6px;
  border: 1px;
  border-style: solid;
  border-color: var(--brand500);
  background-color: var(--brand500);
  color: var(--white);
  font-size: 12px;
}

.btn_icon {
  padding: 4px;
  border-radius: 50%;
  transition-property: background-color, scale;
  transition-duration: 0.2s;

  &:active {
    transform: scale(0.95);
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.default_popover {
  padding: 4px 10px;
  border-radius: 6px;
  border: 1px;
  border-style: solid;
  border-color: var(--gray150);
  background-color: var(--white);
  color: var(--black);
  font-size: 12px;
  text-align: center;
}

.hint_popover {
  padding: 8px 16px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: var(--popover-shadow);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--gray850);
}

.hidden {
  display: none;
}

.number_column {
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
}

.popover {
  padding: 4px 6px;
  border-radius: 4px;
  outline: none;
}

.text_column {
  text-align: left;
}

// simplebar custom styles
.simplebar-scrollbar::before {
  background-color: var(--gray800);
}

.simplebar-track {
  cursor: default;
  pointer-events: auto;
}

// comments
.towards_death_first {
  color: var(--gray800) !important;
}

.towards_death_second {
  color: var(--gray700) !important;
}

.towards_death_third {
  color: var(--gray500) !important;
}

.dead {
  color: var(--gray150) !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
