.container {
  padding: 32px;
  max-width: 617px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
}

.btn_close {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.description {
  padding-top: 16px;
  padding-bottom: 24px;
  color: var(--gray850);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;

  span {
    color: var(--brand500);
  }
}

.buttons_container {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-align: center;
}

.btn_link {
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}

.btn_sign_out {
  background-color: var(--gray50);
  color: var(--gray850);

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray500);
    color: var(--gray100);
  }
}
