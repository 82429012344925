.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 4px;
}

.btn_read_more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 12px;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  background-color: var(--gray50);
  color: var(--gray800);
  font-weight: 500;
  font-size: 14px;

  path {
    fill: var(--gray800);
  }

  &:hover {
    background-color: var(--gray100);
    color: var(--gray850);

    path {
      fill: var(--gray850);
    }
  }

  &:active {
    background-color: var(--gray150);
    color: var(--black);

    path {
      fill: var(--black);
    }
  }
}
