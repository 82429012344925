.container {
  display: flex;
  flex-direction: column;
  width: 433px;
  height: 100%;
  min-width: 433px;
  background-color: var(--white);

  &::-webkit-scrollbar {
    display: none;
  }
}

.is_center {
  border-left: 1px solid var(--gray100);
  border-right: 1px solid var(--gray100);
}

.section_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
  height: 64px;
  background-color: var(--white);
}

.header_left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.section_header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.header_right_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn_pin {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--gray700);
  }

  &:hover {
    path {
      fill: var(--gray850);
    }
  }
}

.pinned {
  position: relative;
  width: 24px;
  height: 24px;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  path {
    fill: var(--gray700);
  }

  &:hover {
    path {
      fill: var(--brand600);
    }
  }
}

.pinned_playing {
  path {
    fill: var(--brand500);
  }
}

.pinned_pulse {
  position: absolute;
  right: 4.5px;
  top: 4.5px;
  display: grid;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.2s;
  animation: pinned_pulse 1.2s 0s ease-out infinite;
}

@keyframes pinned_pulse {
  0% {
    box-shadow: 0 0 2px 1.5px rgb(26 255 179 / 0%), 0 0 0 0 var(--white),
      0 0 0 0 rgb(26 255 179 / 0%);
  }
  10% {
    box-shadow: 0 0 2px 1.5px var(--brand500), 0 0 3px 2.5px var(--white),
      0 0 3px 3.5px var(--brand500);
  }
  100% {
    box-shadow: 0 0 2px 1.5px rgb(26 255 179 / 0%), 0 0 0 10px var(--white),
      0 0 0 10px rgb(26 255 179 / 0%);
  }
}

.section_body_container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.transaction_item_list {
  display: flex;
  flex-direction: column-reverse;
}

.empty_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 96px;
  height: 100%;
  color: var(--gray500);
  letter-spacing: -0.24px;
  text-align: center;
}
