.container {
  width: 100%;
}

.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.section_title_left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.option_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  path {
    fill: var(--gray800);
  }
}

.total_holders {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}
