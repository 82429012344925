.layout_order_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
}

.quicklink_order {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--gray100);
  background-color: var(--white);
  cursor: grab;

  path {
    fill: var(--black);
  }

  &:hover {
    border-color: var(--brand500);
    background-color: var(--brand50);
  }
}

.invisible {
  opacity: 0.2;
}

.isDragging {
  cursor: grabbing;
}

.icn_platform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.btn_visible {
  position: absolute;
  top: 4px;
  left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  path {
    fill: var(--black);
  }

  &:hover {
    path {
      fill: var(--brand500);
    }
  }
}
