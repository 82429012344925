.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  height: 40px;
  background-color: var(--white);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
  transition-duration: 0.6s;
  box-sizing: content-box;
  user-select: none;
  cursor: default;
}

.data_received {
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }

  &:active {
    background-color: var(--gray100);
  }
}

.no_transition {
  transition-duration: 0s;
}

.bound {
  background-color: var(--brand50);
}

.blur {
  filter: blur(8px) grayscale(100%);
}

.thumbnail_container {
  display: flex;
}

.contract_info_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  min-width: 0;
}

.contract_name_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
}

.contract_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.quantity_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  margin-left: 6px;
  height: 16px;
  background-color: var(--brand500);
  border-radius: 14px;
}

.quantity {
  padding: 0 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--white);
}

.time {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.contract_extra_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  white-space: nowrap;
  color: var(--gray700);
}

.extra_divider {
  margin: 0 6px;
  width: 1px;
  height: 8px;
  background-color: var(--gray700);
}

.price_container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.currency {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 6px;

  path {
    fill: var(--gray500);
  }
}

.gas_price_container {
  display: inline-flex;
  align-items: center;
  gap: 2px;

  path {
    fill: var(--gray500);
  }
}

.airdrop_container {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--gray700);
  }
}

.verified_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;

  path {
    fill: var(--brand500);
  }
}

.function_name {
  color: var(--brand500);
  text-overflow: ellipsis;
  overflow: hidden;
}

.end_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  margin-left: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icn_copied {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--brand500);
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;

  path {
    fill: var(--gray850);
  }

  &:hover {
    path {
      fill: var(--brand500);
    }
    background-color: var(--brand50);
  }
}

.button_disabled {
  &:hover {
    path {
      fill: var(--gray850);
    }
    background-color: var(--gray50);
  }
  opacity: 0.25 !important;
  cursor: not-allowed !important;
}

.tx_timestamp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray850);
  text-align: center;
  background-color: var(--gray100);
  border-radius: 4px;
}

.urgent {
  color: var(--red500);
}

.normal {
  color: var(--orange500);
}
