.mint_modal_container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
}

.mint_modal_title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mint_modal_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.mint_modal_body {
  max-height: 613px;
  overflow-y: auto;
}

.shadow {
  position: absolute;
  left: 24px;
  right: 24px;
  z-index: 100;
  height: 28px;
}

.shadow_top {
  top: 78px;
  background: -webkit-linear-gradient(
    270deg,
    var(--scroll-shadow),
    transparent
  );
  background: linear-gradient(180deg, var(--scroll-shadow), transparent);
  opacity: 0;
}

.shadow_bottom {
  bottom: 200px;
  background: -webkit-linear-gradient(90deg, var(--scroll-shadow), transparent);
  background: linear-gradient(0deg, var(--scroll-shadow), transparent);
}

.buttons_container {
  margin-top: 16px;
}

.submit_button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.cancel_button,
.submit_button {
  margin-top: 16px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
}

.cancel_button {
  background-color: var(--gray50);
  color: var(--gray850);

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray500);
    color: var(--gray100);
  }
}

.submit_button {
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
  &:disabled {
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}

.alert_text_container {
  padding-bottom: 24px;
}

.alert_text {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.24px;
}

.disclaimer_link {
  text-decoration: underline !important;

  &:hover {
    color: var(--brand500);
  }
}

.success_toast {
  display: flex;
  flex-direction: column;
}

.tx_link {
  color: var(--gray850);

  &:hover {
    color: var(--black);
    text-decoration: underline;
  }
}
