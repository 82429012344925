.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.contents_container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--gray50);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.total_cost {
  align-self: flex-start;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.cost_info {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.total_minted {
  margin-top: 8px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}
