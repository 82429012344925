.contract_status_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 12px;
  gap: 12px;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;

  path {
    fill: var(--gray850);
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: var(--gray850);
  }
}

.status_icon_container {
  display: flex;
}

.btn_comments {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
}

.btn_hide {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  &:hover {
    path {
      fill: var(--red500);
    }
    span {
      color: var(--red500);
    }
  }
}
