.flag_reason_container {
  display: flex;
  flex-direction: column;
}

.flag_reason_title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.flag_reason {
  margin-top: 8px;
  padding: 12px;
  min-height: 160px;
  border: 1px solid var(--gray150);
  border-radius: 4px;
  background-color: var(--gray50);
  text-overflow: ellipsis;
  transition: 0.15s;
  overflow-y: auto;
  resize: none;

  &:disabled {
    border: 1px solid var(--gray150) !important;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    border-color: var(--brand500) !important;
    background-color: var(--brand50);
  }

  &:hover {
    border-color: var(--gray500);
  }
}

.buttons_container {
  margin-top: 16px;
}

.submit_button,
.cancel_button {
  margin-top: 16px;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
}

.submit_button {
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
}

.cancel_button {
  background-color: var(--gray50);
  color: var(--gray850);

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray500);
    color: var(--gray100);
  }
}
