$first-column-width: 42px;
$second-column-width: 100%;
$last-column-width: 148px;
$cell-height: 38px;

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 0 12px;
  width: 100%;
  height: $cell-height;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: var(--brand50) !important;
  }
}

.even {
  background-color: var(--gray50);
}

.column {
  &:first-of-type {
    span {
      width: $first-column-width;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
  }

  &:nth-of-type(2) {
    min-width: 0;
    width: $second-column-width;
  }

  &:last-of-type(3) {
    width: $last-column-width;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
}

.rank {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: first-column-width;
}

.connected_wallets {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: 0px;
  width: 100%;
}

.quantity_container {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: $last-column-width;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.quantity {
  width: 80px;
}

.percentage {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 64px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.percentage_bar {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 48px;
  height: 2px;
  border-radius: 2px;
  background-color: var(--gray150);
  overflow: hidden;
}

.filled {
  height: 2px;
  border-radius: 2px;
  background-color: var(--gray850);
}

.connected_wallet_item {
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 280px;
  height: 28px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }
}
