$first-column-width: 42px;
$second-column-width: 100%;
$last-column-width: 122px;
$cell-height: 32px;

.wallets_view_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
}

.view_header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 0 12px;
  height: $cell-height;
  border-bottom: 1px solid var(--gray100);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--gray50);
  z-index: 2;
}

.wallets_view_column {
  display: flex;
  height: $cell-height;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray850);

  &:first-of-type {
    border-top-left-radius: 4px;

    span {
      width: $first-column-width;
    }
  }

  &:nth-of-type(2) {
    width: $second-column-width;
  }

  &:last-of-type {
    width: $last-column-width;
    border-top-right-radius: 4px;
  }

  span {
    width: 100%;
  }
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--gray800);
  text-align: center;
  background-color: var(--white) !important;
}
