.chart {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.links {
  display: flex;
}

.nodes {
  display: flex;
}

.node {
  cursor: pointer;
}
