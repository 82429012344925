.layout_order_container {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2%;
  width: 100%;
  overflow: hidden;
}

.layout_block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contract_detail_component_list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
