.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 22px;
  min-width: 960px;
  width: 100%;
  background-color: var(--white);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
  transition-duration: 0.6s;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }

  &:active {
    background-color: var(--gray100);
  }
}

.no_transition {
  transition-duration: 0s;
}

.bound {
  background-color: var(--brand50);
}

.clicked {
  background-color: var(--brand50);

  &:hover {
    background-color: var(--brand50);
  }
}

.collection_info_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
  overflow: hidden;
}

.collection_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;

  &:hover {
    .collection_name {
      text-decoration: underline;
    }
  }
}

.thumbnail_container {
  display: flex;
}

.collection_name_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  overflow: hidden;
}

.collection_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.quantity_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  height: 20px;
  background-color: var(--brand500);
  border-radius: 14px;
}

.quantity {
  padding: 0 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--white);
}

.price_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  // width: 8%;
  width: 76px;
  font-size: 14px;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.gas_price_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  // width: 6%;
  width: 76px;
  font-size: 14px;

  path {
    fill: var(--gray850);
  }
}

.mint_function_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 12%;
  max-width: 152px;

  path {
    fill: var(--brand500);
  }
}

.function_name {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.24px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mintable {
  font-weight: 500;
  color: var(--brand500);
}

.groups_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // width: 10%;
  width: 114px;
}

.avatars_item {
  margin-right: -6px;

  &:nth-of-type(1) {
    z-index: 6;
  }

  &:nth-of-type(2) {
    z-index: 5;
  }

  &:nth-of-type(3) {
    z-index: 4;
  }

  &:nth-of-type(4) {
    z-index: 3;
  }

  &:nth-of-type(5) {
    z-index: 2;
  }

  &:nth-of-type(6) {
    z-index: 1;
  }

  &:hover {
    z-index: 6;
  }
}

.avatars_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  box-shadow: 0 0 0 1px var(--white);
  cursor: pointer;
}

.remaining_avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--brand50);
  border-radius: 12px;
  border: 1px solid var(--brand500);
  box-shadow: 0 0 0 1px var(--white);
  color: var(--brand500);
  font-size: 10px;
}

.minter_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
}

.time_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
  min-width: 120px;

  &:hover {
    text-decoration: underline;
  }
}

.icn_small_external_container {
  path {
    fill: var(--gray850);
  }
}

.transaction_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  font-size: 14px;
}

.confirmation_container {
  margin-left: 4px;
}

.icn_confirmed_container {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--brand500);
  }
}

.icn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
