.price_popover_container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray150);
  border-radius: 6px;
  background-color: var(--white);
}

.price_popover_content {
  padding: 4px 10px;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
}

.price_content {
  margin-left: 4px;
}
