.btn_sweep {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: var(--white);
  border: 2px solid var(--gray700);
  border-radius: 10px;

  svg {
    position: absolute;

    path {
      fill: var(--gray700);
    }
  }

  &:hover {
    border-color: var(--brand500);
    cursor: grab;

    path {
      fill: var(--brand500);
    }
  }
}

.dragging {
  border-color: var(--brand600) !important;

  path {
    fill: var(--brand600) !important;
  }
}

.disabled {
  background-color: var(--gray100) !important;
  border-color: var(--gray500) !important;
  cursor: not-allowed !important;

  path {
    fill: var(--gray700) !important;
  }
}
