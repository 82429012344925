.container {
  display: flex;
  flex-direction: column;
  width: 440px;
  border-right: 1px solid var(--gray100);
  background-color: var(--white);
}

.header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  width: 100%;
  background-color: var(--white);
}

.header_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.btn_reset {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: center;

  &:hover {
    background-color: var(--gray50);
  }
  &:active {
    background-color: var(--gray100);
  }
  &:disabled {
    border: 1px solid var(--gray100);
    background-color: var(--gray50) !important;

    span {
      color: var(--gray700) !important;
    }
    cursor: not-allowed;

    path {
      fill: var(--gray700) !important;
    }
  }
}

.group_search_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
}

.group_search_input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px 12px;
  width: 360px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--gray150);
  border-radius: 4px;
}

.group_search_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.groups_head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  width: 100%;
  border-bottom: 1px solid var(--gray100);
  font-weight: 600;
  font-size: 16px;
}
