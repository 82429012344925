.container {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.pinned {
  background-color: var(--orange50);
  border-radius: 8px;
}

.highlight {
  background-color: var(--brand50);
  border-radius: 8px;
}

.icn_pinned {
  position: absolute;
  top: 0;
  right: 0;

  path {
    fill: var(--orange500);
  }
}

.inner_container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.text_area {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 0px;
  min-width: 0px;
  word-wrap: break-word;
}

.info {
  position: relative;
  display: flex;
  font-size: 12px;
  line-height: 1.5;
}

.username {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  color: var(--gray700);
}

.pinned_username {
  color: var(--gray800);
}

.me {
  color: var(--brand500);
}

.deployer_container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--orange500);
}

.icn_deployer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  path {
    fill: var(--orange500);
  }
}

.status_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  path {
    fill: var(--gray700);
  }
}

.info_side {
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  color: var(--gray700);
}

.pinned_info_side {
  color: var(--gray800);

  .divider {
    background-color: var(--gray800);
  }

  .action {
    path {
      fill: var(--gray800);
    }

    &:enabled:hover {
      color: var(--black);

      path {
        fill: var(--black);
      }
    }
  }
}

.info_side_data {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  height: 12px;
  background-color: var(--gray700);
}

.action {
  display: flex;
  align-items: center;

  path {
    fill: var(--gray700);
  }

  &:enabled:hover {
    color: var(--black);

    path {
      fill: var(--black);
    }
  }
}

.contents_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.no_show {
  display: none;
}

.text {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
  word-break: break-all;
}

.link {
  color: var(--brand500);
  &:visited {
    color: var(--brand700) !important;
  }
  &:hover {
    text-decoration: underline;
  }
}

.edited {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.54px;
}

.reply_btn_box {
  display: inline-flex;
  align-items: center;
}

.reply_btn {
  font-size: 12px;
  color: var(--gray800);
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.vote {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.btn_vote {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;

  path {
    fill: var(--gray800);
  }

  &:enabled:hover {
    path {
      fill: var(--black);
    }
  }
}
