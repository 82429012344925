.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray500);
  border-radius: 24px;
  -webkit-transition: 150ms;
  transition: 150ms;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: var(--white);
  border-radius: 10px;
  -webkit-transition: 150ms;
  transition: 150ms;
}

input:checked + .slider {
  background-color: var(--brand500);
}

input:checked + .slider:before {
  transform: translateX(16px);
}

input:disabled + .slider {
  background-color: var(--gray150);
  cursor: not-allowed;
}
