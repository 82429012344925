.section_title_container {
  margin-bottom: 24px;
}

.section_title {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.icn_external {
  display: flex;
  margin-left: 8px;

  path {
    fill: var(--black);
  }
}

.code_snippets_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.code_snippet_container {
  display: flex;
  flex-direction: column;
}

.code_snippet_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.code_snippet_body {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 16px;
}
