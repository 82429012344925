.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  height: 42px;
  background-color: var(--white);
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
  transition-duration: 0.6s;
  box-sizing: content-box;
  user-select: none;
  cursor: default;
}

.data_received {
  cursor: pointer;

  &:hover {
    background-color: var(--gray50);
  }

  &:active {
    background-color: var(--gray100);
  }
}

.no_transition {
  transition-duration: 0s;
}

.blur {
  filter: blur(8px) grayscale(100%);
}

.bound {
  background-color: var(--brand50);
}

.information_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 39px);
}

.rank {
  margin-right: 24px;
  width: 14px;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--gray850);
}

.thumbnail_container {
  position: relative;
}

.chain_info {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: #292525;
  border-radius: 7px;
}

.text_information_container {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  // margin right is 16px
  width: calc(100% - 96px - 16px);
}

.contract_name_container {
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contract_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.etc_info_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.extra_divider {
  margin: 0 6px;
  width: 1px;
  height: 8px;
  background-color: var(--gray700);
}

.mints_amount {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.verified_container {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--brand500);
  }
}

.mintable {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--brand500);
  background-color: var(--brand50);
  border-radius: 4px;
}

.graph_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 39px;
  height: 26px;
}

.bar {
  width: 4px;
  min-height: 1px;
  background-color: var(--brand500);
  border-radius: 2px;
  transition: height 0.5s ease-out;
}
