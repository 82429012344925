.container {
  display: flex;
  flex-direction: column;
  min-width: 960px;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: var(--white);
}

.header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  width: 100%;
  height: 64px;
  background-color: var(--white);
}

.header_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.header_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.header_filter_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.body_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.activity_columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 8px;
}

.activity_column {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: var(--gray700);

  // Collection
  &:nth-child(1) {
    width: 25%;
  }
  // Price
  &:nth-child(2) {
    justify-content: flex-end;
    width: 76px;
  }
  // Gas
  &:nth-child(3) {
    justify-content: flex-end;
    width: 76px;
  }
  // Mint Function
  &:nth-child(4) {
    width: 12%;
    max-width: 152px;
  }
  // Groups
  &:nth-child(5) {
    justify-content: flex-start;
    width: 114px;
  }
  // Minter
  &:nth-child(6) {
    width: 15%;
  }
  // Time
  &:nth-child(7) {
    justify-content: flex-end;
    width: 10%;
    min-width: 120px;
  }
}
