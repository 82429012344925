.toast_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-height: 480px;
  gap: 12px;
}

.icon {
  .error {
    path {
      fill: var(--red500);
    }
  }
  .success {
    path {
      fill: var(--green500);
    }
  }
}

.contents {
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
  word-break: break-all;
  overflow-y: auto;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray850);
}

.btn_close {
  display: flex;
  path {
    fill: var(--gray850);
  }

  &:hover {
    path {
      fill: var(--gray700);
    }
  }
}
