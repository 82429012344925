.wallet_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  overflow: hidden;
}

.wallet {
  display: flex;
  flex: 1;
  gap: 2px;
  min-width: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray500);
  filter: grayscale(100%);

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  path {
    fill: var(--gray500);
  }

  &:hover {
    filter: grayscale(0);
    color: var(--gray850);
    text-decoration: underline;

    path {
      fill: var(--gray850);
    }
  }
}

.highlight_name {
  color: var(--black);
  filter: grayscale(0);

  path {
    fill: var(--gray850) !important;
  }
}

.icn_external {
  display: inline-flex;
  align-items: center;
}
