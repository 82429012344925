.section_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
}

.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.section_title_left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.hint_container {
  margin-right: 8px;
}

.last_failed {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.total_indicator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.total_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.total_label {
  margin-right: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray800);
  white-space: nowrap;
  user-select: none;
}

.total_data {
  display: flex;
  flex-direction: row;
}

.average_prefix {
  margin-right: 2px;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
}

.error_name,
.method_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
