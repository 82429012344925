.layout_component {
  position: relative;
  display: flex;
  flex: 5;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 6px;
  padding: 12px;
  width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray100);
  font-weight: 600;
  letter-spacing: -0.24px;
}

.contract_detail_layout {
  flex: 9;
}

.highlighting {
  border-color: var(--brand500);
  background-color: var(--brand50);
}

.dragging {
  cursor: grabbing !important;
}

.layout_title_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  user-select: none;

  button {
    margin-right: -8px;
    cursor: grab;

    path {
      fill: var(--gray700);
    }

    &:hover {
      path {
        fill: var(--brand500);
      }
    }
  }
}
