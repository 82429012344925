.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 32px;
  width: 100%;
  border-bottom: 1px solid var(--gray500);
}

.add_announcement_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.announcement_field_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.label_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 8px;
}

.label {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.field_name {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.hint_container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: var(--popover-shadow);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--gray850);
}

.character_limits_guide {
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  color: var(--gray700);
}

.character_limits_exceeded {
  font-weight: 600;
  color: var(--red500);
}

.input {
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  border-color: var(--gray100);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--white);
  outline-color: var(--brand500);
}

.textarea {
  padding: 12px 16px;
  width: 100%;
  min-height: 196px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 16px;
  color: var(--black);
  resize: none;
  outline-color: var(--brand500);
}

.input_warning,
.textarea_warning {
  border-color: var(--red500);
  outline-color: var(--red500);
}

.buttons_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: inherit;
}

.btn_announce,
.btn_cancel {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
}

.btn_announce {
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }

  &:active {
    background-color: var(--brand700);
  }
  &:disabled {
    background-color: var(--gray150);
    cursor: not-allowed;
  }
}

.btn_cancel {
  background-color: var(--gray100);
  color: var(--gray850);

  &:hover {
    background-color: var(--gray150);
  }

  &:active {
    background-color: var(--gray500);
    color: var(--gray100);
  }
}
