.container {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  outline: 0;
  background-color: var(--modal-shadow);
}

.open {
  display: block !important;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.contents_container {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 617px;
  max-width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  transform: translateY(-50%);
  overflow-y: auto;
}

.full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
