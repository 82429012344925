.settings_modal {
  display: flex;
  flex-direction: row;
  width: 968px;
  height: 840px;
}

.left_side_container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 238px;
  background-color: var(--white);
}

.modal_title_container {
  margin-bottom: 24px;
}

.modal_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
}

.section_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.section_list_item {
  padding: 16px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--brand50);
  }
}

.selected {
  background-color: var(--brand50);
  color: var(--brand500);
}

.app_social_container {
  display: flex;
  flex-direction: row;
}

.btn_app_social {
  display: flex;
  flex-direction: row;
  padding: 8px !important;
  align-items: center;
  font-weight: 600;

  path {
    fill: var(--gray850);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    background-color: var(--gray150);
  }
}

.right_side_container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 730px;
  gap: 24px;
}

.right_side_nav_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
}

.btn_close {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.btn_prev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.24px;
  }

  path {
    fill: var(--black);
  }

  &:hover {
    path {
      fill: var(--gray850);
    }
  }
}

.btn_reset {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: center;

  &:hover {
    background-color: var(--brand50);
    border-color: var(--brand500);
    color: var(--brand500);
  }

  &:disabled {
    background-color: var(--white);
    border-color: var(--gray100);
    color: var(--gray700);
    cursor: default;
  }
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: hidden;
}

.section_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  overflow-y: auto;
}

.section_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.section_title_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section_title {
  display: inline-flex;
  flex-direction: row;
  gap: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.section_description {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.btn_next {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--gray800);

  path {
    fill: var(--gray800);
  }

  &:hover {
    color: var(--gray850);

    path {
      fill: var(--gray850);
    }
  }
}

.fixed_buttons_container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.btn_save {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-align: center;
  background-color: var(--brand500);
  color: var(--white);

  &:hover {
    background-color: var(--brand600);
  }
  &:active {
    background-color: var(--brand700);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray150);
  }
}
