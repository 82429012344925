.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 12px;
  gap: 8px;
  width: 100%;
  border: 1px solid var(--gray150);
  border-radius: 8px;
  background-color: var(--gray50);
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: var(--brand500);
    background-color: var(--brand50);
  }
}

.container_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.icn_announcement {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }
}

.urgent {
  path {
    fill: var(--red500);
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.time {
  padding-left: 4px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray800);
  white-space: nowrap;
}

.btn_read {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  font-weight: 500;
  font-size: 12px;
  color: var(--brand500);
}
