.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.inner_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.skeleton_container {
  display: flex;
  flex-direction: column;
  max-width: 169px;
  overflow: hidden;
}

.skeleton_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  max-width: 169px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  aspect-ratio: 1;
  line-height: 1;
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--gray800);
  text-align: center;
  background-color: var(--white) !important;
}
