.label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 28px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--gray50);
  font-size: 12px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  user-select: none;
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  path {
    fill: var(--gray850);
  }
}

.highlight {
  border-color: var(--brand500);
  background-color: var(--brand50);
  color: var(--brand500);

  path {
    fill: var(--brand500);
  }
}

.clickable {
  cursor: pointer;
}

.disabled {
  border-color: var(--gray100);
  background-color: var(--gray50);
  color: var(--gray500);
  cursor: default;

  path {
    fill: var(--gray500);
  }
}
