.container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
  margin-left: 24px;
  padding: 6px 12px;
  width: 100%;
  height: 40px;
  border: 1px solid var(--gray100);
  border-radius: 8px;
  background: var(--gray100);
  transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
    border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;

  &:hover {
    border-color: var(--gray50);
    background: var(--gray50);
  }
}

.input {
  width: 358px;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--black);
  text-overflow: ellipsis;

  &::selection {
    background-color: var(--gray150);
  }
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.focus {
  border: 1px solid var(--brand500);
  background-color: var(--white);

  &:hover {
    border-color: var(--brand500);
    background-color: var(--white);
  }
}

.typing {
  border: 1px solid var(--brand500);

  svg {
    path {
      fill: var(--brand500);
    }
  }
}

.shortcut_key {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--gray500);
  background-color: var(--gray50);
  font-size: 10px;
  color: var(--gray800);
  text-align: center;
  user-select: none;
}
