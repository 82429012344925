.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  outline: 0;
}

.dimmer {
  background-color: var(--modal-shadow);
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
  box-shadow: var(--popover-shadow);

  &:focus-visible {
    outline: none;
  }
}

.menu_item {
  position: relative;
  background-color: var(--white);

  &:hover {
    background-color: var(--gray50);
  }

  > &:hover > .tooltip_box {
    opacity: 1;
    visibility: visible;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:disabled {
    color: var(--gray500);
    background-color: var(--gray100);
  }
}

.tooltip_box {
  position: absolute;
  width: 460px;
  top: 42px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms linear;
}

.tooltip {
  position: relative;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--brand500);
  border-radius: 4px;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  box-shadow: var(--popover-shadow);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 8px;
    border-width: 6px;
    border-style: solid;
    border-color: var(--brand500);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
