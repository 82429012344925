.flag_details_container {
  position: absolute;
  top: -1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 670px;
}

.left {
  left: -686px;
}

.right {
  right: -686px;
}

.notable_flag_table {
  max-height: 416px;
  overflow-y: auto;
}

.table {
  margin: 1px;
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  outline: hidden;
  box-shadow: 0 0 0 1px var(--gray100);
  background-color: var(--white);
  overflow-y: hidden;

  .table_header_cell {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .no_data {
    height: 42px;
    text-align: center;
    background-color: var(--white) !important;
  }

  thead {
    position: sticky;
    top: 1px;
    padding-top: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 0 1px var(--gray100);
    background-color: var(--gray50);

    tr {
      th {
        padding: 0 12px;
        min-width: 70px;
        white-space: nowrap;
      }
      th:first-child {
        width: 172px;
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: var(--white);
    }
    tr:nth-child(even) {
      background-color: var(--gray50);
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
    tr {
      &:hover {
        background-color: var(--brand50);
      }
    }
  }

  th,
  td {
    letter-spacing: -0.24px;
    &:last-of-type {
      font-weight: 700;
    }
  }

  th {
    height: 32px;
    background-color: var(--gray50);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--gray850);
  }

  td {
    padding: 8px 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    word-break: break-all;
    word-wrap: break-word;
  }
}

.column_hint {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.recaptcha_guide_container {
  width: 100%;
}
