.link_wallet_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.how_to_link_container {
  display: flex;
  flex-direction: column;
}

.how_to_link_title {
  font-weight: 600;
  font-size: 16px;
  color: var(--black);
}

.how_to_link_description {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray700);
}

.linked_wallets_container {
  display: flex;
  flex-direction: column;
  height: 484px;
  overflow-y: auto;
}
