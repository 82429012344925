.container {
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
  gap: 24px;
  width: 100%;
  border: 1px solid var(--gray150);
  border-radius: 8px;
  background-color: var(--white);
  transition-property: background-color;
  transition-duration: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--gray100);
  }
}

.info_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.name_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: 700;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address {
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.etc_container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.chain_ethereum {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #e0e2e8;
}

.btn_customize {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;

  path {
    fill: var(--gray850);
  }

  &:enabled:hover {
    background-color: var(--gray50);

    path {
      fill: var(--black);
    }
  }
}
