.container {
  position: absolute;
  top: 48px;
  right: 8px;
}

.inner_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 440px;
  max-height: 693px;
  background-color: var(--white);
  border-radius: 12px;
  filter: drop-shadow(var(--notification-modal-shadow));
}

.header {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 10px;
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid var(--gray100);
}

.body {
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--gray850);
  font-size: 16px;
  font-weight: 600;
}

.list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
