.connect_container {
  padding: 32px;
  max-width: 617px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.connect_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.24px;
}

.connect_description {
  padding-top: 16px;
  padding-bottom: 24px;
  color: var(--gray850);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.24px;
  a {
    color: var(--brand500);
  }
}

.connect_connect_buttons_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.external_link {
  &:hover {
    text-decoration: underline !important;
  }
}
