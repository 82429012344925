.container {
  position: fixed;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 56px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray100);
  z-index: 99;
}

.center_container,
.left_container,
.right_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.center_container {
  justify-content: center;
  gap: 16px;
}

.left_container {
  justify-content: flex-start;
  gap: 16px;
}

.right_container {
  justify-content: flex-end;

  & > *:last-child {
    margin-left: 16px;
  }
}

.nav_container {
  display: flex;
  flex-direction: row;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.nav_button_container {
  position: relative;
}

.nav_button {
  display: flex;
  flex-direction: row;
  padding: 8px !important;
  align-items: center;
  font-weight: 600;

  path {
    fill: var(--gray850);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    background-color: var(--gray150);
  }
}

.other_nav_item {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  color: var(--gray850);
  cursor: pointer;

  &:hover {
    color: var(--black);
  }
}
