.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  margin-top: 6px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--gray100);
  background-color: var(--white);
  overflow: hidden;
}

.thumbnail_container {
  display: flex;
  flex-shrink: 0;
  margin-right: 20px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  filter: blur(4px);
}

.info_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
}

.contract_name_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  width: 100%;
  pointer-events: none;
  user-select: none;
  filter: blur(4px);
}

.contract_name {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contract_chain_info_container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  pointer-events: none;
  user-select: none;
  filter: blur(4px);
}

.address_container {
  display: inline-flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.labels_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.buttons_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
}

.example_button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: var(--gray50);
  opacity: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--gray100);
  }

  &:active {
    background-color: var(--gray150);
  }

  path {
    fill: var(--gray850);
  }
}
