.modal_address_input_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
}

.address_input_label_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 16px;
}

.address_input_label {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.address_input_btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.address_input_paste_btn {
  padding: 4px 8px;
  background-color: var(--brand50);
  border-radius: 4px;
  color: var(--brand500);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;

  &:hover {
    background-color: var(--brand500);
    color: var(--brand50);
  }
}

.btn_address_input_arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-right: 8px;
  padding: 4px 8px;
  font-size: 12px;
  background-color: var(--white);
  border: 1px solid var(--gray150);
  border-radius: 4px;

  path {
    fill: var(--gray700);
  }

  &:hover {
    background-color: var(--gray50);

    path {
      fill: var(--gray800);
    }
  }
}

.address_input_select_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 8px 12px;
  font-size: 14px;
  text-align: left;

  path {
    fill: var(--gray500);
  }
}

.address_input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.address_input {
  width: 100%;
  background-color: var(--white);
  border: none;
  outline: none;
}

.btn_clear {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;

  path {
    fill: var(--gray500);
  }

  &:hover {
    svg {
      path {
        fill: var(--gray700);
      }
    }
  }
}
