.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--gray50);
}

.maintenance_container {
  max-width: 617px;
  background-color: var(--white);
  border-radius: 16px;
}

.maintenance_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 70px;

  .maintenance_title {
    margin: 24px 0 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.24px;
    text-align: center;
  }

  .maintenance_description {
    color: var(--gray850);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24px;
    text-align: center;

    a {
      color: var(--brand500);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
