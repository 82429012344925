.group_info_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
}

.new {
  display: inline-flex;
  flex-shrink: 0;
  padding: 0 4px;
  height: 16px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-color: var(--orange500);
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.24px;
  color: var(--white);
}

.group_link {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
}

.group_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}
