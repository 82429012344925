.contract_detail_component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  padding-left: 12px;
  width: 100%;
  height: 36px;
  background-color: var(--white);
  border: 1px solid var(--gray100);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.24px;
  cursor: grab;

  &:hover {
    border-color: var(--brand500);
    background-color: var(--brand50);
  }
}

.invisible {
  opacity: 0.4;
}

.isDragging {
  cursor: grabbing;
}

.visible_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  path {
    fill: var(--black);
  }

  &:hover {
    path {
      fill: var(--brand500);
    }
  }
}
