.option_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.option_list_item {
  display: inline-flex;
  align-items: center;

  label {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;

    span {
      margin-left: 8px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: var(--gray850);
    }
  }
}
