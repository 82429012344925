.div_view_container{
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);

    .div_view_header{
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      padding: 0 12px;
      height: 32px;
      border-bottom: 1px solid var(--gray100);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: var(--gray50);
      z-index: 2;
    }

    .div_view_list {
        display: flex;
        height: 32px;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray850);

        .div_view_column {
            height: 100%;
            padding: 8px 12px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            word-break: break-all;
            word-wrap: break-word;
        }

        .text_column{
          text-align: left;
        }

        .number_column{
          text-align: right;
        }
    }

    .div_view_list:nth-child(odd) {
      background-color: var(--gray50);

      .div_view_column {
        background-color: var(--gray50);
      }
    }
    .div_view_list:nth-child(even) {
      background-color: var(--white);

      .div_view_column {
        background-color: var(--white);
      }
    }
    .div_view_list:last-child {
      .div_view_column:first-child {
        border-bottom-left-radius: 4px;
      }

      .div_view_column:last-child {
        border-bottom-right-radius: 4px;
      }
    }
    .div_view_list {
      &:hover {
        background-color: var(--brand50);

        .div_view_column {
          background-color: var(--brand50);
        }
      }
    }
}