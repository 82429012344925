.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}

.hidden {
  display: none;
}

.announcements {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.btn_add_announcement {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px;
  width: 100%;
  border: 2px dashed var(--gray500);
  border-radius: 8px;
  font-weight: 500;

  color: var(--gray800);

  path {
    fill: var(--gray800);
  }

  &:hover {
    border: 2px solid var(--black);
    background-color: var(--white);
    color: var(--black);

    path {
      fill: var(--black);
    }
  }
}
