.table_container {
  display: flex;
  overflow-y: auto;
}

.table {
  table-layout: fixed;
  margin: 1px;
  width: calc(100% - 2px);
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  outline: hidden;
  box-shadow: 0 0 0 1px var(--gray100);
  background-color: var(--white);
  overflow-y: hidden;

  thead {
    position: sticky;
    top: 1px;
    padding-top: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 0 1px var(--gray100);
    background-color: var(--gray50);
    z-index: 1;

    tr {
      th {
        padding: 0 12px;
      }
      th:first-child {
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: var(--white);
      td {
        background-color: var(--white);
      }
    }
    tr:nth-child(even) {
      background-color: var(--gray50);
      td {
        background-color: var(--gray50);
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
    tr {
      &:hover {
        background-color: var(--brand50);
        td {
          background-color: var(--brand50);
        }
      }
    }
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  th {
    height: 32px;
    background-color: var(--gray50);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--gray850);
  }
  td {
    padding: 8px 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    word-break: break-all;
    word-wrap: break-word;
  }
}
