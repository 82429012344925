.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.filter_container {
  display: flex;
  flex-direction: row;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 28px;
  border-style: solid;
  border-width: 1px;
  border-left-color: var(--gray100);
  border-top-color: var(--gray100);
  border-right-color: transparent;
  border-bottom-color: var(--gray100);
  background-color: var(--white);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  user-select: none;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-color: var(--gray100);
  }

  &:hover {
    background-color: var(--gray50);
  }
}

.notable_groups_modal_body {
  display: flex;
  flex-direction: column;
}

.link_request_more_groups_to_be_added {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray850);

  path {
    fill: var(--gray850);
  }

  &:hover {
    text-decoration: underline;
  }
}

.active {
  border-width: 1px;
  border-color: var(--brand500) !important;
  background-color: var(--brand50);
  color: var(--brand500);

  path {
    fill: var(--brand500);
  }

  &:hover {
    background-color: var(--brand50);
  }
}

.popup_checkbox {
  accent-color: var(--brand500);
}
