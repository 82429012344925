.container {
  position: relative;
  min-height: 72px;
  list-style-type: none;
}

.inner_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 32px;
  background-color: var(--white);
  overflow: hidden;

  &:hover {
    background-color: var(--brand50);
  }
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.username {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  color: var(--gray700);
}

.pinned_username {
  color: var(--gray800);
}

.me {
  color: var(--brand500);
}

.deployer_container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--orange500);
}

.icn_deployer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  path {
    fill: var(--orange500);
  }
}

.date {
  display: flex;
  align-items: center;
  color: var(--gray700);
  white-space: nowrap;
}

.edited {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.54px;
}

.collection_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
  min-width: 0;
  text-decoration-color: var(--gray800);
  color: var(--gray800);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.collection_thumbnail {
  margin-right: 6px;
}

.collection_name {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.icn_pinned {
  margin-left: 8px;

  path {
    fill: var(--orange500);
  }
}

.body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 4px;
}

.text {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
  word-break: break-all;
}

.link {
  color: var(--brand500);
  &:visited {
    color: var(--brand700) !important;
  }
  &:hover {
    text-decoration: underline;
  }
}

.action {
  display: flex;
  align-items: center;

  path {
    fill: var(--gray700);
  }

  &:enabled:hover {
    color: var(--black);

    path {
      fill: var(--black);
    }
  }
}

.num_of_comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray700);

  path {
    fill: var(--gray700);
  }

  &:hover {
    color: var(--brand500);

    path {
      fill: var(--brand500);
    }
  }
}
