.table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  outline: hidden;
  box-shadow: 0 0 0 1px var(--gray100);

  .current {
    background-color: var(--brand50) !important;
  }

  tr:last-child {
    &:first-child {
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  th {
    padding: 0 12px;
    height: 52px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.24px;

    &:nth-of-type(1) {
      width: 182px;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 76px;
    }
    &:nth-of-type(4),
    &:nth-of-type(5) {
      width: 79px;
    }
    &:nth-of-type(6) {
      width: 72px;
    }
  }

  td {
    padding: 0 12px;
    height: 56px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.24px;

    &:nth-of-type(4),
    &:nth-of-type(5) {
      font-size: 12px;
    }
  }

  thead {
    tr {
      th:first-child {
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }
      background-color: var(--gray50);
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: var(--gray50);
    }
    tr:nth-child(even) {
      background-color: var(--white);
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.similar_contract {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.similar_contract_thumbnail {
  cursor: pointer;
}

.similar_contract_info_container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  font-family: Poppins;
  overflow: hidden;
}

.similar_contract_name {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.current_contract_name {
  color: var(--brand500);
  cursor: auto;
  &:hover {
    text-decoration: none;
  }
}

.similar_contract_address_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.similar_contract_address {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--brand500);
}

.flag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;

  path {
    fill: var(--gray850);
  }
}

.flag_active {
  color: var(--red500);
  path {
    fill: var(--red500);
  }
}
