.container {
  display: inline-block;
  font-size: 12px;
  color: var(--gray500);
  backdrop-filter: blur(40px);

  a {
    color: var(--brand500);

    &:hover {
      text-decoration: underline;
    }
  }
}
