.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pulse {
  margin: 0 6px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--gray700);
  transition: all 0.35s ease-in-out;
}

.pulse_loading {
  background-color: var(--yellow500);
}

.pulse_playing {
  background-color: var(--red500);
  animation: pulse 1.2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--red500);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.live_text {
  color: var(--gray700);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  transition: all 0.35s ease-in-out;
  user-select: none;
}

.live_text_loading {
  color: var(--yellow500);
}

.live_text_playing {
  color: var(--red500);
}
