$first-column-width: 60px;
$second-column-width: 60px;
$third-column-width: 80px;
$fourth-column-width: 80px;
$fifth-column-width: 36px;
$sixth-column-width: 36px;

.container {
  position: relative;
  display: flex;
  flex-shrink: 0;
  padding: 0 12px;
  height: 48px;
  width: 100%;
  background-color: var(--white);
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--brand50);
  }
}

.focused {
  background-color: var(--brand50);

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    width: 4px;
    height: inherit;
    background-color: var(--brand500);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.inner_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.left_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  overflow: hidden;
}

.right_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thumbnail {
  display: flex;
}

.typed_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.text_box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  em {
    font-style: normal;
    font-weight: 600;
    color: var(--brand500);
  }
}

.address {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  font-variant-numeric: lining-nums tabular-nums;
  color: var(--gray800);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.column {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.24px;

  &:nth-of-type(1) {
    width: $first-column-width;
  }
  &:nth-of-type(2) {
    width: $second-column-width;
  }
  &:nth-of-type(3) {
    margin-left: 4px;
    width: $third-column-width;
  }
  &:nth-of-type(4) {
    width: $fourth-column-width;
  }
  &:nth-of-type(5) {
    width: $fifth-column-width;
  }
  &:nth-of-type(6) {
    width: $sixth-column-width;
  }
}
