.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  margin-top: 8px;
}

.textarea {
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  font-size: 14px;
  color: var(--black);
  resize: none;
  outline-color: var(--brand500);
}

.active {
  height: auto;
  min-height: 112px;
}

.btn_add_comment {
  margin-top: 6px;
  padding: 4px 8px;
  width: fit-content;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray850);
  border: 1px solid var(--gray100);
  border-radius: 4px;
  transition: color 200ms linear, border-color 200ms linear;

  &:disabled {
    color: var(--gray700);
    background-color: var(--gray100);
    cursor: not-allowed;
  }

  &:hover:enabled {
    color: var(--black);
    border-color: var(--black);
  }
}
