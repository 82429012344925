.username_search_bar_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  width: 440px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--gray150);
  border-radius: 4px;
}

.username_search_bar_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.search_status_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.possible {
  path {
    fill: var(--green500);
  }
}

.impossible {
  path {
    fill: var(--red500);
  }
}
