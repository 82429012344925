.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: var(--brand50);
  border: 1px solid var(--brand500);
  border-radius: 24px;
}

.name {
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
}

.btn_disconnect {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--red400);
  }

  &:hover {
    background-color: var(--gray100);

    path {
      fill: var(--red500);
    }
  }
}
