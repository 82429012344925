.section_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.section_header_inner_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.guidelines_link {
  display: inline-flex;
  gap: 2px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray800);

  path {
    fill: var(--gray800);
  }

  &:hover {
    color: var(--black);
    text-decoration: underline;

    path {
      fill: var(--black);
    }
  }
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}

.btn_refresh {
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--gray100);
  }
  &:active {
    path {
      fill: var(--gray800);
    }
  }
}

.total_count {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
}

.inner_container {
  display: flex;
  flex-direction: column;
}

.comment_list {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  overflow: auto;
}
