.character_limits_guide {
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  color: var(--gray700);
}

.thumbnail_container {
  display: flex;
  flex-shrink: 0;
  margin-right: 20px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  overflow: hidden;

  &:hover {
    .upload_background,
    .upload_label {
      visibility: visible;
    }
  }
}

.hint_container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: var(--popover-shadow);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--gray850);
}

.upload_background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  background-color: var(--black);
  visibility: hidden;
  opacity: 0.6;
}

.upload_label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  visibility: hidden;
  cursor: pointer;

  path {
    fill: var(--white);
  }
}

.link_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.link_name_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  path {
    fill: var(--black);
  }
}

.link_name {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: var(--black);
}

.link_input_container {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  border: 1px solid var(--gray100);
  border-radius: 4px;
  background-color: var(--white);
}

.link_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
