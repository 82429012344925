.flag_button {
  display: flex;
  flex-direction: row;
  align-items: center;

  path {
    fill: var(--gray850);
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: var(--gray850);
    white-space: nowrap;
  }

  &:hover {
    path {
      fill: var(--red400);
    }
    span {
      color: var(--red400);
    }
  }
}

.flagged {
  span {
    padding-left: 4px;
    color: var(--red500);
  }
  path {
    fill: var(--red500);
  }
}

.flag_type_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--gray850);

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.flagged {
  color: var(--red500);
}

.flag_label {
  padding-right: 24px;
}

.flag_count {
  font-size: 16px;
}

.flagged_type {
  font-weight: 600;
  color: var(--red500);
}
