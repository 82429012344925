.contract_info_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--white);
  overflow: hidden;
}

.thumbnail_container {
  display: flex;
  flex-shrink: 0;
  margin-right: 20px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  overflow: hidden;

  &:hover {
    .lens_background,
    .lens_thumbnail {
      visibility: visible;
    }
  }
}

.lens_background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  background-color: var(--gray100);
  visibility: hidden;
  opacity: 0.8;
}

.lens_thumbnail {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  visibility: hidden;
}

.contract_info_text_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
}

.contract_name_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  width: 100%;
}

.first_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contract_name {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contract_chain_info_container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
}

.contract_address_container {
  display: inline-flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: var(--gray850);
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.labels_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.codes {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: var(--gray100);
  font-size: 10px;
  color: var(--red500);
  text-shadow: var(--white) 0px 1px;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
}

.buttons_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
}

.contract_indicator_container {
  display: flex;
  flex-direction: row;
  padding-top: 16px;

  li:not(:last-child) {
    margin-right: 16px;
  }
}

.section_title {
  padding-bottom: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.contract_info_footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.contract_info_deploy_container {
  display: flex;
  flex-direction: column;

  p {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: var(--gray700);
    user-select: none;

    .deployer {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0px 6px;
      padding: 2px 8px;
      background: var(--gray50);
      border: 1px solid var(--gray100);
      border-radius: 4px;
      color: var(--gray850);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.max_supply_alerts_modal {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 4px 10px;
  background-color: var(--white);
  border: 1px solid var(--gray150);
  border-radius: 6px;
}

.column {
  display: flex;
  flex-direction: column;
}

.column_title {
  margin-bottom: 4px;
  font-size: 12px;
  color: var(--gray800);
  text-align: left;
}

.max_supply {
  font-size: 16px;
  line-height: 26px;
  color: var(--gray500);
  text-align: left;

  &:nth-child(2) {
    font-weight: 600;
    color: var(--black);
  }
}

.edited_time {
  font-size: 14px;
  line-height: 26px;
  color: var(--gray500);
  text-align: right;

  &:nth-child(2) {
    color: var(--black);
  }
}

.time {
  display: inline-flex;
  align-items: center;
  color: var(--gray850);
}

.icn_external {
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  path {
    fill: var(--gray850);
  }
}

.deployment_tx_hash {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.show_deploy {
  margin-top: 20px;
}

.etc_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
}

.chain_info_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--gray100);
}

.btn_customize {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;

  path {
    fill: var(--gray850);
  }

  &:enabled:hover {
    background-color: var(--gray100);

    path {
      fill: var(--black);
    }
  }
}

.announcement_container {
  margin-top: 16px;
}
