.section_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}

.section_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.last_updated {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: var(--gray700);
}

.line_height_zero {
  // for removing table cell heigh
  line-height: 0 !important;
}

.market {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  span {
    font-family: Poppins;
    margin-left: 8px;
    font-weight: 500;
    color: var(--black);
  }

  &:hover {
    text-decoration: underline;
  }
}

.icn_market {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
}

.royalty_description_link {
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

.icn_external {
  display: flex;
  margin-left: 4px;

  path {
    fill: var(--black);
  }
}

.unavailable {
  line-height: 24px !important;
  text-align: center;
}
