.text_column {
  text-align: left;
}

.number_column {
  text-align: right;
}

.table_element_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table_default_container {
  gap: 4px;
  overflow: hidden;
}

.no_data {
  height: 42px;
  text-align: center;
  background-color: var(--white) !important;
}
